import React, { FC } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";

import { StandardHeader } from "./StandardHeader";
import { StandardFooter } from "./StandardFooter";
import theme from "../gatsby-theme-material-ui-top-layout/theme";


type StandardPageContainerProps = {
  title: string
}

export const StandardPageContainer:FC<StandardPageContainerProps> = ({ title, children }) => {
  const columnDisplay = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <Helmet title={title} />

      <StandardHeader />
      
      <Box padding={columnDisplay ? 2 : 8} bgcolor={theme.palette.strongbackground}>
        {children}
      </Box>

      <StandardFooter />
    </Box>
  );
};

export default StandardPageContainer;
